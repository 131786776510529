.margin-10px{
    margin: 10px !important;
}

.margin-t-10px{
    margin-top: 10px !important;
}

.margin-r-10px{
    margin-right: 10px !important;
}

.margin-b-10px{
    margin-bottom: 10px !important;
}

.margin-l-10px{
    margin-left: 10px !important;
}

.margin-5px{
    margin: 5px !important;
}

.margin-t-5px{
    margin-top: 5px !important;
}

.margin-r-5px{
    margin-right: 5px !important;
}

.margin-b-5px{
    margin-bottom: 5px !important;
}

.margin-l-5px{
    margin-left: 5px !important;
}

.margin-0px{
    margin: 0px !important;
}

.margin-t-0px{
    margin-top: 0px !important;
}

.margin-r-0px{
    margin-right: 0px !important;
}

.margin-b-0px{
    margin-bottom: 0px !important;
}

.margin-l-0px{
    margin-left: 0px !important;
}
