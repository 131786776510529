@import "../../../Margin.css";

.mainContentArea {
    display: grid;
    grid-template-rows: 65px auto;
    grid-template-columns: auto;
    height: 100%;
}

@media screen and (max-width: 520px) {
    .main {
        display: grid;
        grid-template-rows: auto auto;
        grid-template-columns: auto;
    }
}

.page {
    background-color: var(--background);
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: min-content auto;
    height: calc(100% - 65px);
}

.main-content {
    width: 100%;
    padding: 24px 64px;
    overflow: auto;
}

.main-content.full-screen {
    padding: 0;
}

.main-content>.row,
.row>.row
.row {
    display: grid;
    grid-template-rows: auto;
    row-gap: 9px;
    width: 100%
}

.col-1-auto {
    grid-template-columns: auto;
}

.col-2-auto {
    grid-template-columns: auto auto;
}

.col-3-auto {
    grid-template-columns: auto auto auto;
}

.col-2-auto-100px {
    grid-template-columns: auto 100px;
}

.col-2-auto-150px {
    grid-template-columns: auto 150px;
}

.col-2-20 {
    grid-template-columns: 20% auto;
}

.col-2-50 {
    grid-template-columns: 50% auto;
}

.col {
    margin: 10px;
}

.drag-bar {
    background-color: var(--sideBar);
    height: 100%;
    cursor: ew-resize;
}

.content {
    padding: 0px 10px;
    border-radius: 10px;
    width: 100%;
    min-width: 800px;
}

.content>.row {
    display: grid;
    grid-template-rows: auto;
    row-gap: 9px;
    margin: 10px 0px;
}

.title {
    justify-content: center;
    width: 100%;
    padding: 0px 10px;
    background: none;
    display: inline-block;
    border-radius: 10px;
}

.content>.content-title {
    font-weight: bold;
    margin: 10px 0px;
}

.required-field {
    color: red;
}