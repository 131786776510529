.appBar {
  background-color: var(--primary);
}

.dynamicText{
  white-space: nowrap;
  margin-left: 10px;
}

.buttonContainer{
  border-left: 1px solid #ccc;
  margin-left: 24px;
  max-width: 52vw;
}

.topBarIcon{
  width: 55px;
  margin-right: 8px;
}

.topBarTitle {
  flex-grow: unset;
  color: var(--fontPrimary);
}

.customComponentContainer{
  margin-left: auto;
  margin-right: 8px;
}

.app-menu-container{
  display: flex;
  width: 52vw;
}

.menu-container {
  width: auto;
  margin: auto 0;
}

.mobileMenu {
  z-index: 1000;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: var(--primary);
  overflow: auto;
}

.mobileMenuContainer {
  margin-right: 24px;
  padding-right: 24px;
  border-right: 1px solid #ccc;
}

.mobileAppMenuContainer {
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #ccc;
  display: flex;
  justify-content: center;
}

.mobileButtonContainer .dynamicText {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}

.mobileIconMenuContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.mobileCustomComponentContainer {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}