.banner {
    width: 100%;
    height: 20px;
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    text-align: center;
    position: fixed;
    font-family: 'Liberation Sans', Helvetica, sans-serif;
    z-index: 999999;
}

.top-banner {
    height: 20px;
    top: 0;
}

.bottom-banner {
    height: 20px;
    top: calc(100vh - 20px);
}

.unclassified {
    background-color: #007a33;
    color: #FFFFFF;
}

.cui {
    background-color: #502b85;
    color: #FFFFFF;
}
.confidential {
    background-color: #0033A0;
    color: #FFFFFF;
}

.secret {
    background-color: #C8102E;
    color: #FFFFFF;
}

.top-secret {
    background-color: #FF671F;
    color: #FFFFFF;
}

.top-secret-sci {
    background-color: #F7EA48;
    color: #000000;
}