/* Global constants for the css */
:root {
  /* background colors */
  --blueBackground: #d0e8fb;
  --greenBackground: #d6f5dd;
  --redBackground: #f7d4d7;
  --whiteBackground: #FAFAFA;
  --yellowBackground: #fff2cc;

  /* general use colors */
  --black: #000000;
  --blue: #1070bc;
  --gray: #d3d3d3;
  --green: #28a745;
  --red: #dc3545;
  --white: #FFFFFF;
  --yellow: #ffc107;

  /* opacity */
  --inactive: 0.28;
  --inactiveButtonFont: .5;
  --inactiveHeaderOpacity: 0.26;
  /* TODO: Move these to their own components  when they exist */
  --opacitySideBar: 0.54;
  --opacityTableSelected: 0.54;

  /* Base colors */
  --primary: #1070bc;
  --secondary: #d0e8fb;
  --background: #FAFAFA;
  --sidebar: #ededed;
  --hyperlink: #1070bc;
  --hyperlinkVisited: #551a8b;
  --divider: #cccccc;

  /* Inactive colors */
  --primaryInactive: #000000;
  --secondaryInactive: #000000;
  --backgroundInactive: #d3d3d3;
  --fontHeaderInactive: #000000;

  /* font colors */
  --fontPrimary: #ffffff;
  --fontSecondary:#000000;
  --fontBackground: #000000;
  --fontSideBar: #000000;
  --fontHeader: #000000;
  --fontPrimaryBorder: rgba(255, 255, 255, 0.54);
  --fontSecondaryBorder: rgba(0,0,0,.54);
  
  /* Focus for component outlines */
  --primaryFocusOutline: rgba(0, 0, 0, 0.04);
  --secondaryFocusOutline: rgba(255, 255, 255, 0.04);

  /* Base font family */
  --fontFamily: "Helvetica";

  --baseFontSize: "16px";

  --toastify-color-info: #1070bc;
  --toastify-color-success: #28a745;
  --toastify-color-warning: #ffc107;
  --toastify-color-error: #dc3545;
  --toastify-font-family: Helvetica;

  --default-dashboard-card-height: auto;
  --default-dashboard-card-width: auto;
  --default-dashboard-card-side-color: #1070bc;
}
