.App {
  text-align: center;
}

.Root {
  box-sizing: border-box !important;
}

html,
body {
  height: 100%;
}

a:link {
  color: var(--hyperlink);
}

a:visited {
  color: var(--hyperlinkVisited);
}

.MuiFormControlLabel-root {
  margin: 0 !important;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: var(--white);
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: var(--fontBackground);
}

.App-link {
  color: var(--hyperlink);
}

.links {
  color: var(--fontPrimary);
}

.formControl {
  color: var(--fontPrimary);
  min-width: 240px;
}

option {
  color: var(--fontBackground);
}

.buttonStyle.MuiIconButton-root {
  float: right;
  color: var(--fontPrimary);
  display: inline;
}

.responsiveStyle {
  height: 50vh;
  overflow-y: scroll;
  text-align: left;
  list-style-type: none;
  padding-inline-start: 0px;
}

.drawerPaper {
  background-color: var(--sidebar);
  z-index: 0;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 20%);
  border-right: 1px solid rgba(0, 0, 0, 30%);
  height: 100%;
  overflow: hidden;
}

.form-control {
  color: var(--fontPrimary);
  min-width: 120;
}

.selectEmpty {
  color: var(--fontPrimary);
  float: left;
}

.selectEmpty>.MuiInputBase-input {
  color: var(--fontPrimary);
  border-bottom: 1px solid white;
}

.selectEmpty .MuiNativeSelect-icon {
  color: var(--fontPrimary);
}

.responsiveGroupTableStyle {
  height: 60vh;
  width: 50% !important;
  overflow-y: scroll;
}

.addUserStyle {
  height: 40vh;
  width: 30vw;
  overflow-y: scroll;
}

.CreateUserbutton {
  height: 40px;
  font-weight: bold;
  font-size: 16px;
  width: 100%;
}

.roleForm {
  display: grid;
  grid-gap: 10px;
  grid-template-rows: 60px auto 40px 40px;
  grid-template-columns: auto;
}

.userListContainer {
  border: 1px solid rgb(143, 143, 143);
  border-radius: 5px 0 0 5px;
  height: 60vh;
  overflow-y: auto;
}

.landing-page {
  height: calc(100% - 65px);
}

.landing-center {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.landing-center>img {
  height: auto;
  width: auto;
  max-width: 90%;
  max-height: 90%;
}

.landing-text {
  margin-left: 5em;
  margin-right: 5em;
}

.unlicensed-alert {
  margin: 2vh;
}

.row input[type="text"],
.row input[type="password"],
.col* input[type="text"],
.col* input[type="password"],
.content input[type="text"],
.content input[type="password"] {
  width: 100%;
  height: 40px;
  font-size: 16px;
}

.row input,
.col* input,
.content input {
  padding: 5px;
  margin: 10px 0px;
  background-color: var(--white);
}

.MuiFormControl-root {
  margin: 10px 0 !important;
}
/* Calendar Card */
@import "../node_modules/@syncfusion/ej2-base/styles/material.css";
@import "../node_modules/@syncfusion/ej2-buttons/styles/material.css";
@import "../node_modules/@syncfusion/ej2-react-calendars/styles/material.css";