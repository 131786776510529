.efe-sidenav-rotate {
  transform: rotate(-0.50turn);
}

.side-nav {
  display: flex;
  flex-direction: column;
}

.side-nav-collapsed {
  width: 3.5rem;
}

.side-nav-expanded {
  width: 17.5rem;
}

.arrow-div {
  flex-grow: 1;
  display: flex;
}

.expanded-arrow-div {
  padding-left: calc(100% - 3rem);
}