/******  LOGIN PAGE  ******/
.login-container{
    display: flex;
    align-items: center;
    background-repeat: no-repeat;
    justify-content: center;
}

.cardCreateUser {
  text-align: center;
}

.densePadding{
  align-items: baseline;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
/* Add some padding inside the card container */
.login-container .container {
  padding-left: 7%;
  padding-right: 7%;
  height: 100%;
}
.login-container .container h1{
  font-size: 2.5rem;
}
.login-container .container form{
  display: flex;
  height: 80%;
  flex-direction: column;
  justify-content: space-around;
}

.spinner {
  animation: spinner infinite .9s linear;
  height: 90%;
}
.spinner:focus{
  border:none;
}
@keyframes spinner {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.form-error{
  color:  var(--primary);
  text-align: center;
}
  
/******  LOGIN PAGE  ******/